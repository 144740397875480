import * as React from "react"

import ButtonPrimaryArrow from "components/buttons/ButtonPrimaryArrow"

const ContactCTABlock = () => {
  return (
    <div className="container text-center">
      <h2 className="text-4xl mb-4">Would you like to discuss more?</h2>
      <p className="text-3xl md:text-3xl mb-8 font-light leading-relaxed md:leading-relaxed">Our global team of advisors with years of combined experience in the marine sector are on hand to discuss your project.</p>
      <p className="flex justify-center"><ButtonPrimaryArrow title="Contact Us" url="/contact-us/" /></p>
    </div>
  )
}
export default ContactCTABlock
