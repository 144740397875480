import React from "react"
import { Link } from "gatsby"
import { ArrowRightIcon } from '@heroicons/react/solid'

function ButtonPrimaryArrow({ title, url }) {
  return (
    <div className="flex">
      <Link to={url} className="btn-primary btn-icon">
        {title}
        <ArrowRightIcon className="ml-4 h-5 w-5 text-white" />
      </Link>
    </div>
  )
}
export default ButtonPrimaryArrow
