import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

import ContactCTABlock from "components/blocks/ContactCTA"

import SubmenuEnvi from "components/menus/submenu-envi"


const GlobalServiceNetworkPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/ships-aerial-dark-dockside.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
        sectionImage: file(relativePath: { eq: "backgrounds/scrubber-lift.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
      }
    `
    )

    return (
        <Layout>
            <Seo title="ENVI-Marine™ - Excellent Aftersales Support" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-80 lg:flex">
                    <TitleBox title="Excellent Aftersales Support from our Global Service Network" caption="ENVI-Marine™">

                    </TitleBox>
                </div>
            </ImgBackground>

            {/* Content Area */}
            <section className="py-16 lg:py-32 pb-8 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12">

                        <div className="mb-12 lg:w-1/4">
                            <SubmenuEnvi className="" />
                        </div>

                        <div className="mb-12 lg:w-3/4 lg:pr-12">

                            <p className="text-2xl mb-12">
                                With numerous global manufacturing bases, the Pacific Green Technologies Global Service Network offers total reassurance of support wherever and whenever it is required and keeps costs down, maximising your return on your gas scrubber investment.
                            </p>

                            <div className="prose max-w-none">
                                <p>With the knowledge, skills and support of the Pacific Green Technologies Global Service Network, you can rely on your equipment for as long as you own it. Our committed team will ensure high performance that will keep you competitive throughout your equipment’s life cycle.</p>
                                <p>Our licensed installation and service centres are trained to both fit, and maintain, Pacific Green Technologies’ equipment. Our factory-trained personnel are on hand throughout the process to provide support and security for you and your vessel.</p>
                            </div>


                        </div>
                    </div>

                </div>
            </section>

            {/* Content Area */}
            <ImgBackground className="py-40 lg:py-80" image={data.sectionImage}>
                <div className="container">
                </div>
            </ImgBackground>

            <section className="py-16 lg:py-32">
                <div className="container">

                    <div className="lg:flex lg:space-x-20">
                        <div className="lg:w-1/3">
                            <div className="prose max-w-none mx-auto">
                                <h2>Pacific Green is pleased to offer an annual service agreement plan for its ENVI-Marine™ Exhaust Gas Cleaning System.</h2>
                                <p>We recommend the following plan to maintain the ENVI-Marine Exhaust gas scrubbers within your fleet.
                                    This service agreement can be undertaken over a span of 3 years. The onboard inspections would take place with an initial inspection after exiting the guarantee period and a second visit 2 years later.</p>
                                <p className="bg-primary-300 p-4">You could make an estimated saving of up to $37,700.00 per vessel over 3 years, with a more streamlined approach to maintenance</p>
                            </div>
                        </div>
                        <div className="lg:w-3/4">
                            <div className="prose max-w-none mx-auto bg-primary-50 p-12">
                                <h3>Part A – Onboard Inspection / Total of 2 Inspections </h3>
                                <p>- EGCS body, CEMS, WWMS, PLC</p>

                                <h3>Part B – Sensor Exchange Pool / Total of 3 Exchanges  </h3>
                                <p>- Sensor Exchange for the WWMS</p>

                                <h3>Part C – Bi-Annual Remote Inspection / Total of 6 Reports </h3>
                                <p>- Bi-annual remote reports on the systems performance <br />- Remote assistance for trouble shooting</p>

                                <h3>Part D – Recommended Spares</h3>
                                <p>- Bulk delivery of annual spares to decrease costs associated to delivery</p>



                            </div>
                        </div>
                    </div>

                </div>
            </section>




            {/* CTA contact block */}
            <section className="bg-primary-50 py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout >
    )
}

export default GlobalServiceNetworkPage
