import React from "react"
import { Link } from "gatsby"

import {ArrowRightIcon as Icon} from "@heroicons/react/outline"

const SubMenu = ({ menu, className }) => {
    return (
        <nav className={className}>
            {menu.map(item => (
                <Link
                key={item.name}
                to={item.link}
                className="flex justify-between items-center border-b border-gray-200 hover:text-primary py-2 text-base font-medium" 
                activeClassName="text-gray-500" 
            >
                <span>{item.name}</span>
                <Icon className="w-4 h-4 mr-2"/>
            </Link>
            ))}
        </nav>

    )
}

export default SubMenu