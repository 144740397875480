import * as React from "react"
import SubMenu from "./submenu-links"

const SubmenuEnvi = ({className}) => {
    
    const menulinks = [
        {
            name:'ENVI-Marine™',
            link: '/envi-marine/'
        },
        {
            name:'How it Works',
            link: '/envi-marine/how-does-it-work/'
        },
        {
            name:'Open / Closed Loop?',
            link: '/envi-marine/open-or-closed-loop/'
        },
        {
            name:'Global Service Network',
            link: '/envi-marine/global-service-network/'
        },

    ]

    return (
         <SubMenu menu={menulinks} className={className} />
    )

}

export default SubmenuEnvi
